<template>
	<div>
		<Header login="false"></Header>
		<div class="container login">
			<div class="m1440">
				<div class="flex-box flex-center login_warp">
					<img src="../assets/static/icon/login_left.png" class="login_left" alt=""
						v-if="tabIndex == 1 || tabIndex == 2" />
					<div class="sign_box" v-if="tabIndex == 1 || tabIndex == 2">
						<!-- sign up -->
						<!-- <template v-if="tabIndex == 0">
							<div class="title tc">Sign Up</div>
							<template v-if="!isCheckEmail">
								<el-input v-model="email" placeholder="Email address"></el-input>
								<a href="javascript:;" class="btn fs21 mt30 mb15" @click="signup">Sign Up</a>
							</template>
							<template v-else>
								<el-input v-model="account" placeholder="Screen Name or Player ID" class="mb15"></el-input>
								<el-input v-model="pwd" placeholder="Password" class="mb15"></el-input>
								<el-input v-model="referralCode" placeholder="Referral Code" class="mb40"></el-input>
								<el-checkbox v-model="agreement" @change="agree"><span class="fs14">I agree to the
									</span><router-link to="/terms?type=term" class="blue fs14">Terms &
										Conditions</router-link></el-checkbox>
								<a href="javascript:;" class="btn fs21 mt20 mb15" @click="">Sign Up</a>
							</template>
							<div class="fs16 tc">Already have an account? <a href="javascript:;" class="blue"
									@click="changeTab(1)">Sign in</a></div>
						</template> -->
						<!-- sign in -->
						<template v-if="tabIndex == 1">
							<div class="title tc">{{ $t('signin') }}</div>
							<el-input v-model="account" placeholder="Screen Name or Player ID" class="mb15"></el-input>
							<el-input v-model="pwd"  type="password"  placeholder="Password" class="mb15"></el-input>
							<el-checkbox v-model="agreement" @change="agree"><span class="fs14">I agree to the
								</span><router-link to="/terms?type=term" class="blue fs14">Terms &
									Conditions</router-link></el-checkbox>
							<a href="javascript:;" @click="signin" class="btn fs21 mt20 mb15">Sign In</a>
							<div class="fs16 tc">Don't have an account? <a href="javascript:;" class="blue"
									@click="changeTab(0)">Create for free</a></div>
							<a href="javascript:;" class="tc red fs16 block pt20" @click="changeTab(2)">Forgot Password?</a>
						</template>
						<!-- forget password -->
						<template v-if="tabIndex == 2">
							<div class="title tc">Forgot Password?</div>
							<el-input v-model="email" placeholder="Email address"></el-input>
							<a href="javascript:;" class="btn fs21 mt30 mb15" @click="sendforget">Send Verification Code</a>
							<div class="fs16 tc">Remember Password? <a href="javascript:;" class="blue"
									@click="changeTab(1)">Sign In</a></div>
						</template>
						<template v-if="tabIndex == 3">
							<div class="title tc">{{$t('signup')}}</div>
							<el-input v-model="account" placeholder="Screen Name" class="mb15"></el-input>
							<el-input v-model="pwd"  type="password"   placeholder="Password" class="mb15"></el-input>
							<el-input v-model="referralCode" placeholder="Referral Code" class="mb40"></el-input>
							<el-checkbox v-model="agreement" @change="agree">I agree to the <router-link
									to="/terms?type=term" class="blue">Terms & Conditions</router-link></el-checkbox>
							<a href="javascript:;" class="btn fs21 mt20 mb15" @click="register">{{$t('signup')}}</a>
							<div class="fs16 tc">{{$t('haveaccont')}} <a href="javascript:;" class="blue"
									@click="changeTab(1)">{{$t('signin')}}</a></div>
						</template>
					</div>
					<div class="sign_up" v-else-if="tabIndex == 0">
						<div class="fs40 pb20 title">{{$t('signup')}}</div>
						<div class="fs18 col5 pb10">{{$t('playerprofile')}}</div>
						<div class="flex-box">
							<a href="javascript:;" class="btn fs16 " :class="type == 2 ? 'new' : ''" @click="changetype(1)">NEW
								{{$t('newprofile')}}</a>
							<div class="fs18 plr20 col10">OR</div>
							<a href="javascript:;" class="btn fs16" :class="type == 1 ? 'new' : ''"
								 @click="changetype(2)">{{$t('exsitingprofile')}}</a>
						</div>
						<template v-if="type == 2">
							<div class="fs18 col5 pb10 pt30">{{$t('signupcode')}}</div>
							<div class="flex-box">
								
								<el-input v-model="inviteid" placeholder="" class="large mr30"></el-input>
								<!-- <el-input v-model="accessid" placeholder="" class="small mr30"></el-input>
								<el-input v-model="position" placeholder="" class="small mr30"></el-input> -->
								<!-- <el-input v-model="referralCode" @input="search" placeholder=""
									class="small mr30"></el-input>
								<el-input v-model="level" :readonly="true" placeholder="" class="small"></el-input> -->
							</div>
							<div class="fs18 col5 pb10 pt30">{{$t('cardnum')}}</div>
							<div class="flex-box">
								<!-- <el-input v-model="inviteid" placeholder="" class="small mr30"></el-input>
								<el-input v-model="accessid" placeholder="" class="small mr30"></el-input>
								<el-input v-model="position" placeholder="" class="small mr30"></el-input> -->
								<el-input v-model="referralCode" @input="search" placeholder=""
									class="small mr30"></el-input>
								<!-- <el-input v-model="level" :readonly="true" placeholder="" class="small"></el-input> -->
								<div class="small">{{ level }}</div>

							</div>

							<div class="fs18 col5 pb10 pt30">{{$t('screenname')}}</div>
							<el-input v-model="account" placeholder="Player Screen Name" class="large"></el-input>
							<div class="fs18 col5 pb10 pt30">{{$t('password')}}</div>
							<el-input v-model="pwd"  type="password"   placeholder="Password" class="large mb30"></el-input>
						</template>
						<template v-else>
							<div class="fs18 col5 pb10 pt30">Email address</div>
							<el-input v-model="email" placeholder="Email address" class="large mb30"></el-input>
						</template>
						<div>
							<el-checkbox v-model="agreement" @change="agree"><span class="fs14">I agree to the
								</span><router-link to="/terms?type=term" class="blue fs14">Terms &
									Conditions</router-link></el-checkbox>
						</div>
						<a href="javascript:;" class="btn fs21 mt20 mb15 sign" @click="register2">{{$t('signup')}}</a>
						<div class="fs16 tc already">{{$t('haveaccont')}} <a href="javascript:;" class="blue"
								@click="changeTab(1)">{{$t('signin')}}</a></div>
						<div style="width:620px;">&nbsp;</div>
					</div>
					<div class="sign_up" v-else-if="tabIndex == 3">
						<div class="fs40 pb20 title">{{$t('signup')}}</div>

						<div class="fs18 col5 pb10 pt30">{{$t('signupcode')}}</div>
						<div class="flex-box">
							<el-input v-model="inviteid" placeholder="" class="large mr30"></el-input>
							<!-- <el-input v-model="accessid" placeholder="" class="small mr30"></el-input>
							<el-input v-model="position" placeholder="" class="small mr30"></el-input> -->
							<!-- <el-input v-model="referralCode" @input="search" placeholder=""
								class="small mr30"></el-input>
							<el-input v-model="level" :readonly="true" placeholder="" class="small"></el-input> -->
						</div>
						<div class="fs18 col5 pb10 pt30">{{$t('cardnum')}}</div>
						<div class="flex-box">
							<!-- <el-input v-model="inviteid" placeholder="" class="small mr30"></el-input>
							<el-input v-model="accessid" placeholder="" class="small mr30"></el-input>
							<el-input v-model="position" placeholder="" class="small mr30"></el-input> -->
							<el-input v-model="referralCode" @input="search" placeholder=""
								class="small mr30"></el-input>
							<!-- <el-input v-model="level" :readonly="true" placeholder="" class="small"></el-input> -->
							<div class="small">{{ level }}</div>

						</div>
						<div class="fs18 col5 pb10 pt30">{{$t('screenname')}}</div>
						<el-input v-model="account" placeholder="Player Screen Name" class="large"></el-input>
						<div class="fs18 col5 pb10 pt30">{{$t('password')}}</div>
						<el-input v-model="pwd" type="password" placeholder="Password" class="large mb30"></el-input>

						<div>
							<el-checkbox v-model="agreement" @change="agree"><span class="fs14">I agree to the
								</span><router-link to="/terms?type=term" class="blue fs14">Terms &
									Conditions</router-link></el-checkbox>
						</div>
						<a href="javascript:;" class="btn fs21 mt20 mb15 sign" @click="register3">{{$t('signup')}}</a>
						<div class="fs16 tc already">{{$t('haveaccont')}} <a href="javascript:;" class="blue"
								@click="changeTab(1)">{{$t('signin')}}</a></div>
					</div>
				</div>
				<div class="statement">
					<div class="fs20 col10 fwb pb20">STATEMENT</div>
					<div v-html="articledetail"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import cookie from "vue-cookies";
import axios from "axios";
import CryptoJS from 'crypto-js'
import { parse } from "qs";
import { TimeSelect } from "element-ui";

export default {
	watch: {
		$route: function (to, from) {
			// console.log(query)
			if (!this.$route.query.tabindex) {
				var params = ''
				console.log(this.$route.query)
				for (let key in this.$route.query) {
					params = key
				}
				let reg = new RegExp(' ', 'g')//g代表全部
				params = params.replace(reg, '+');

				params = JSON.parse(this.decrypt(params, "123sd"))
				console.log(params)
				this.tabIndex = params.tabindex

				// this.$route.query=params
				if (params.docardid) {
					this.docard_id = params.docardid
				}



			} else {
				console.log(888)
				this.tabIndex = this.$route.query.tabindex

			}
		},
	},
	data() {
		return {
			email: '',//邮箱地址
			isCheckEmail: false,//是否输入邮箱且点击了signup
			account: '',//账号
			pwd: '',//密码
			referralCode: '',//推荐码
			agreement: false,//协议
			tabIndex: 0,//登录注册下标 0signup 1signin 2forgetpassword
			hassend: 0,
			docard_id: 0,
			articledetail: '',
			type: 1, //1新用户 2关联
			inviteid: '',
			accessid: '',
			position: '',
			level: ''
		};
	},
	beforeCreate() {

	},
	created() {
		// console.log(query)
		if (!this.$route.query.tabindex) {
			var params = ''
			console.log(this.$route.query)
			for (let key in this.$route.query) {
				params = key
			}
			let reg = new RegExp(' ', 'g')//g代表全部
			params = params.replace(reg, '+');

			params = JSON.parse(this.decrypt(params, "123sd"))
			console.log(params)
			this.tabIndex = params.tabindex

			// this.$route.query=params
			if (params.docardid) {
				this.docard_id = params.docardid
			}
			if (params.email) {
				this.email = params.email
			}


		} else {
			console.log(888)
			this.tabIndex = this.$route.query.tabindex

		}
		this.config()


	},

	methods: {
		search(e) {
			console.log(e)
			this.$api.apiSearchcardd({ code: e }).then((res) => {
				if (res.code == 1) {
					this.level = res.data.level.price
				}
			})

		},
		changetype(type) {
			this.type = type
		},
		config() {
			this.$api.config({}).then((res) => {
				if (res.code == 1) {
					console.log(88)
					this.articledetail = res.data.statement
				} else {
					// that.$message.error(res.msg);
					this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
				}
			})
		},
		// 切换登录注册
		changeTab(index) {
			console.log(index);
			this.tabIndex = index;
		},
		// 加密方法
		encrypt(str, key) {
			return CryptoJS.AES.encrypt(str, key).toString()
		},
		// 解密方法
		decrypt(ciphertext, key) {
			console.log(888)
			let bytes = CryptoJS.AES.decrypt(ciphertext, key)
			console.log(bytes)
			return bytes.toString(CryptoJS.enc.Utf8)
		},

		decode(str) {
			// Going backwards: from bytestream, to percent-encoding, to original string.
			return decodeURIComponent(atob(str).split('').map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));
		},
		//加密
		encode(str) {
			return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
				function toSolidBytes(match, p1) {
					return String.fromCharCode('0x' + p1);
				}));
		},
		// 同意协议
		agree(e) {
			console.log(e)
		},
		// signup输入邮箱后点击按钮
		checkEmail() {
			this.isCheckEmail = !this.isCheckEmail;
		},
		inputemail(e) {
			console.log(e)
			this.email = e
		},
		sendforget() {
			var email = this.email
			if (!email) {
				// this.$message.error('请填写邮箱');
				this.$message({
					message: '请填写邮箱',
					type: 'error',
					offset: window.screen.height / 2
				})
			}
			const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;

			if (!regEmail.test(this.email)) {
				// 合法的邮箱
				// return this.$message.error("邮箱格式错误");
				return this.$message({
					message: "邮箱格式错误",
					type: 'error',
					offset: window.screen.height / 2
				})
			}
			if (this.hassend == 0) {
				this.hassend = 1
				this.$api.apiForgetPassword({ email: email, type: 1 }).then((res) => {
					console.log(res)

					if (res.code == 1) {
						this.email = ''
						this.hassend = 0
						// this.$message.success('发送成功')
						this.$message({
							message: "发送成功",
							type: 'success',
							offset: window.screen.height / 2
						})
					} else {
						this.hassend = 0
						// this.$message.error(res.msg);
						this.$message({
							message: res.msg,
							type: 'error',
							offset: window.screen.height / 2
						})

					}
				})
			} else {
				// this.$message.error("邮件已发送");
				this.$message({
					message: "邮件已发送",
					type: 'error',
					offset: window.screen.height / 2
				})
			}

		},

		signin() {
			console.log(this.account)
			if (!this.account) {
				// return this.$message.error("请输入账号或ID")
				return this.$message({
					message: "请输入账号或ID",
					type: 'error',
					offset: window.screen.height / 2
				})
			}
			if (!this.pwd) {
				// return this.$message.error("请输入密码")
				return this.$message({
					message: "请输入密码",
					type: 'error',
					offset: window.screen.height / 2
				})

			}
			if (!this.agreement) {
				// return this.$message.error("请阅读并同意Terms & Conditions")
				return this.$message({
					message: "请阅读并同意Terms & Conditions",
					type: 'error',
					offset: window.screen.height / 2
				})

			}
			this.$api.apiPostLogin({ account: this.account, pwd: this.pwd }).then(ret => {
				if (ret.code == 1) {
					this.email = "";
					this.pwd = "";
					this.agreement = false

					cookie.set("sma_token", ret.data.userinfo.token);
					cookie.set("sma_userinfo", ret.data.userinfo);
					axios.defaults.headers['token'] = ret.data.userinfo.token;
					// axios.defaults.headers.post["token"] =

					this.$router.push({ path: '/' })
					// var url = window.localStorage.getItem('gopdf_url');
					// if(url){
					//   window.localStorage.removeItem('gopdf_url');
					//   window.open(url, '_blank')
					// }
				} else {
					// this.$message.error(ret.msg)
					this.$message({
						message: ret.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
				}
			})

		},
		signup() {
			if (!this.email) {
				// return this.$message.error('请填写邮箱')
				return this.$message({
					message: "请填写邮箱",
					type: 'error',
					offset: window.screen.height / 2
				})
			}
			const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;

			if (!regEmail.test(this.email)) {
				// 合法的邮箱
				// return this.$message.error("邮箱格式错误");
				return this.$message({
					message: "邮箱格式错误",
					type: 'error',
					offset: window.screen.height / 2
				})
			}

			if (this.hassend == 0) {

				this.hassend = 1
				var string = JSON.stringify({ tabindex: 3, docardid: this.docard_id })
				// this.inviteurl = ' https://p.xilukeji.com/sma/sma/dist/index.html#/login?' + this.encrypt(string, "123sd")
				// this.inviteurl = 'http://139.196.154.95/sma/dist/index.html#/login?' + this.encrypt(string, "123sd")
				// var url = ' https://p.xilukeji.com/sma/sma/dist/index.html#/login?' + this.encrypt(string, "123sd")
				// var url = 'http://139.196.154.95/sma/dist/index.html#/login?' + this.encrypt(string, "123sd")
				var url ='http://www.smamodle.com/#/login?'+this.encrypt(string,"123sd")

				// var url = 'http://localhost:8080#/login?' + this.encrypt(string, "123sd")

				this.$api.apiForgetPassword({ email: this.email, type: 2, docardid: this.docard_id, url }).then((res) => {
					console.log(res)

					if (res.code == 1) {
						this.email = ''
						this.hassend = 0

						// this.$message.success('发送成功')
						this.$message({
							message: "发送成功",
							type: 'success',
							offset: window.screen.height / 2
						})
					} else {
						// this.$message.error(res.msg);

						this.hassend = 0
						this.$message({
							message: res.msg,
							type: 'error',
							offset: window.screen.height / 2
						})
					}
				})
			} else {
				// this.$message.error("邮件已发送");
				this.$message({
					message: "邮件已发送",
					type: 'error',
					offset: window.screen.height / 2
				})
			}
		},
		register2() {

			if (this.type == 2) {
				if (!this.inviteid) {
					// return this.$message.error("请准确填写数据");
					return this.$message({
						message: "请准确填写数据",
						type: 'error',
						offset: window.screen.height / 2
					})
				}
				var invitecode=this.decrypt(this.inviteid,"123sd")
				invitecode=invitecode.split('-')
				console.log(invitecode);
				var inviteid=invitecode[0]
				this.accessid=invitecode[1]
				this.position=invitecode[2]
				if (!this.accessid) {
					// return this.$message.error("请准确填写数据");
					return this.$message({
						message: "请准确填写数据",
						type: 'error',
						offset: window.screen.height / 2
					})
				}
				if (!this.position) {
					// return this.$message.error("请准确填写数据");
					return this.$message({
						message: "请准确填写数据",
						type: 'error',
						offset: window.screen.height / 2
					})
				}
				if (!this.referralCode) {
					// return this.$message.error("请填写推荐码");
					return this.$message({
						message: "请填写推荐码",
						type: 'error',
						offset: window.screen.height / 2
					})
				}
				if (!this.account) {
					// return this.$message.error("请填写账号");
					return this.$message({
						message: "请填写账号",
						type: 'error',
						offset: window.screen.height / 2
					})
				}
				if (!this.pwd) {
					// return this.$message.error("请填写密码");
					return this.$message({
						message: "请填写密码",
						type: 'error',
						offset: window.screen.height / 2
					})
				}
				if (!this.agreement) {
					// return this.$message.error("请阅读并同意Terms & Conditions")
					return this.$message({
						message: "请阅读并同意Terms & Conditions",
						type: 'error',
						offset: window.screen.height / 2
					})

				}
			} else {
				if (!this.email) {
					// return this.$message.error('请填写邮箱')
					return this.$message({
						message: "请填写邮箱",
						type: 'error',
						offset: window.screen.height / 2
					})
				}
				const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;

				if (!regEmail.test(this.email)) {
					// 合法的邮箱
					// return this.$message.error("邮箱格式错误");
					return this.$message({
						message: "邮箱格式错误",
						type: 'error',
						offset: window.screen.height / 2
					})
				}
				if (!this.agreement) {
					// return this.$message.error("请阅读并同意Terms & Conditions")
					return this.$message({
						message: "请阅读并同意Terms & Conditions",
						type: 'error',
						offset: window.screen.height / 2
					})
				}
				if (this.hassend == 0) {

					this.hassend = 1
					// , docardid: this.docard_id
					var string = JSON.stringify({ tabindex: 3,email:this.email })
					// this.inviteurl = ' https://p.xilukeji.com/sma/sma/dist/index.html#/login?' + this.encrypt(string, "123sd")
					// this.inviteurl = 'http://139.196.154.95/sma/dist/index.html#/login?' + this.encrypt(string, "123sd")
					// var url = ' https://p.xilukeji.com/sma/sma/dist/index.html#/login?' + this.encrypt(string, "123sd")
					// var url = 'http://139.196.154.95/sma/dist/index.html#/login?' + this.encrypt(string, "123sd")
					var url ='http://www.smamodle.com/#/login?'+this.encrypt(string,"123sd")

					// var url = 'http://localhost:8080#/login?' + this.encrypt(string, "123sd")
					// docardid: this.docard_id,
					this.$api.apiForgetPassword({ email: this.email, type: 2, url }).then((res) => {
						console.log(res)

						if (res.code == 1) {
							this.email = ''
							this.hassend = 0

							// this.$message.success('发送成功')
							this.$message({
								message: "发送成功",
								type: 'success',
								offset: window.screen.height / 2
							})
						} else {
							// this.$message.error(res.msg);

							this.hassend = 0
							this.$message({
								message: res.msg,
								type: 'error',
								offset: window.screen.height / 2
							})
						}
					})
				} else {
					// this.$message.error("邮件已发送");
					this.$message({
						message: "邮件已发送",
						type: 'error',
						offset: window.screen.height / 2
					})
				}
				return false
			}


			// , docardid: this.docard_id
			var data = { type: this.type, idnum: this.account, password: this.pwd, invitecode: this.referralCode, invite_id: inviteid, access_id: this.accessid, position: this.position }
			this.$api.apiRegister(data).then(ret => {
				if (ret.code == 1) {
					this.account = "";
					this.pwd = "";
					this.agreement = false
					this.invitecode = false

					cookie.set("sma_token", ret.data.userinfo.token);
					cookie.set("sma_userinfo", ret.data.userinfo);
					axios.defaults.headers['token'] = ret.data.userinfo.token;
					// axios.defaults.headers.post["token"] =

					this.$router.push({ path: '/' })
				} else {
					// this.$message.error(ret.msg)
					this.$message({
						message: ret.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
				}
			})
		},
		register3() {
			if (!this.inviteid) {
				// return this.$message.error("请准确填写数据");
				return this.$message({
						message: "请准确填写数据",
						type: 'error',
						offset: window.screen.height / 2
					})
			}
			var invitecode=this.decrypt(this.inviteid,"123sd")
			invitecode=invitecode.split('-')
			console.log(invitecode);
			var inviteid=invitecode[0]
			this.accessid=invitecode[1]
			this.position=invitecode[2]
			if (!this.accessid) {
				// return this.$message.error("请准确填写数据");
				return this.$message({
						message: "请准确填写数据",
						type: 'error',
						offset: window.screen.height / 2
					})
			}
			if (!this.position) {
				// return this.$message.error("请准确填写数据");
				return this.$message({
						message: "请准确填写数据",
						type: 'error',
						offset: window.screen.height / 2
					})
			}
			if (!this.referralCode) {
				// return this.$message.error("请填写推荐码");
				return this.$message({
						message: "请填写推荐码",
						type: 'error',
						offset: window.screen.height / 2
					})
			}

			if (!this.account) {
				// return this.$message.error("请填写账号");
				return this.$message({
						message: "请填写账号",
						type: 'error',
						offset: window.screen.height / 2
					})
			}
			if (!this.pwd) {
				// return this.$message.error("请填写密码");
				return this.$message({
						message: "请填写密码",
						type: 'error',
						offset: window.screen.height / 2
					})
			}

			if (!this.agreement) {
				// return this.$message.error("请阅读并同意Terms & Conditions")
				return this.$message({
						message: "请阅读并同意Terms & Conditions",
						type: 'error',
						offset: window.screen.height / 2
					})

			}
			// docardid: this.docard_id,
			var data = { type: 1,email:this.email,  idnum: this.account, password: this.pwd, invitecode: this.referralCode, invite_id: inviteid, access_id: this.accessid, position: this.position }
			console.log(data)
			// return false
			this.$api.apiRegister(data).then(ret => {
				if (ret.code == 1) {
					this.account = "";
					this.pwd = "";
					this.agreement = false
					this.invitecode = false

					cookie.set("sma_token", ret.data.userinfo.token);
					cookie.set("sma_userinfo", ret.data.userinfo);
					axios.defaults.headers['token'] = ret.data.userinfo.token;
					// axios.defaults.headers.post["token"] =

					this.$router.push({ path: '/' })
				} else {
					// this.$message.error(ret.msg)
					this.$message({
						message: ret.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
				}
			})
		},
		register() {
			if (!this.account) {
				// return this.$message.error("请填写账号");
				return this.$message({
						message: "请填写账号",
						type: 'error',
						offset: window.screen.height / 2
					})
			}
			if (!this.pwd) {
				// return this.$message.error("请填写密码");
				return this.$message({
						message: "请填写密码",
						type: 'error',
						offset: window.screen.height / 2
					})
			}
			if (!this.referralCode) {
				// return this.$message.error("请填写推荐码");
				return this.$message({
						message: "请填写推荐码",
						type: 'error',
						offset: window.screen.height / 2
					})
			}
			if (!this.agreement) {
				// return this.$message.error("请阅读并同意Terms & Conditions")
				return this.$message({
						message: "请阅读并同意Terms & Conditions",
						type: 'error',
						offset: window.screen.height / 2
					})

			}
			var data = { docardid: this.docard_id, idnum: this.account, password: this.pwd, invitecode: this.referralCode }
			this.$api.apiRegister(data).then(ret => {
				if (ret.code == 1) {
					this.account = "";
					this.pwd = "";
					this.agreement = false
					this.invitecode = false

					cookie.set("sma_token", ret.data.userinfo.token);
					cookie.set("sma_userinfo", ret.data.userinfo);
					axios.defaults.headers['token'] = ret.data.userinfo.token;
					// axios.defaults.headers.post["token"] =

					this.$router.push({ path: '/' })
				} else {
					// this.$message.error(ret.msg)
					this.$message({
						message: ret.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
				}
			})
		}
	},
};
</script>
